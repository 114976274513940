















import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import { useLandingPage } from '@/composition/useLandingPage'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Home',

  components: {
    SiteHeader,
    SiteFooter,
  },

  setup() {
    const { page } = useLandingPage({
      name: 'Home',
      sections: [
        {
          id: 'hero',
          name: 'Hero',
        },
        {
          id: 'quienes-somos',
          name: 'AboutUs',
          isDisplayedInHeader: true,
        },
        {
          id: 'afiliaciones',
          name: 'Affiliations',
          isDisplayedInHeader: true,
        },
        {
          id: 'compromiso-social',
          name: 'SocialCommitment',
        },
        {
          id: 'servicios',
          name: 'Services',
          isDisplayedInHeader: true,
        },
        {
          id: 'proyectos',
          name: 'Projects',
          isDisplayedInHeader: true,
        },
        {
          id: 'clientes',
          name: 'Clients',
          isDisplayedInHeader: true,
        },
        {
          id: 'marcas',
          name: 'Brands',
        },
        {
          id: 'contacto',
          name: 'Contact',
          isDisplayedInHeader: true,
        },
      ],
    })

    return {
      page,
    }
  },
})
